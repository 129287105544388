import * as React from "react"
import Layout from "../../components/layout"

import ColumnWithIcon from "../../modules/columnWithIcon";
import Image from "../../modules/imageComponent";
import Paginator from "../../modules/paginator";

import anim1 from '../../images/animations/icon_12/animation'
import anim2 from '../../images/animations/icon_11/animation'
import anim3 from '../../images/animations/icon_10/animation'
import Lottie from "react-lottie-player";
import mainAnim from "../../images/animations/main_icon_2/animation";

const SwapPage = () => {
    return (
        <Layout className={'technology'} title={'Swap'}>
            <section>
                <div className={'container'}>

                    <div className={'row pb-0 mb-0 align-items-center'}>
                        <div className={'col col-12 col-md-4 col-lg-3 col-md-3'}>
                        </div>
                        <div className={'col col-12 col-md-8 col-lg-9'}>
                            <Paginator current={3} prev={2} next={4}/>
                        </div>
                    </div>
                    <div className={'row pb-lg-5 align-items-center'}>
                        <div className={'col col-12 col-md-4 col-lg-3 py-5 py-md-0 pe-md-5 hero-image swap'}>
                            <Lottie animationData={mainAnim} play loop={false}/>
                        </div>
                        <div className={'col col-12 col-md-5 align-top'}>
                            <h1>Swap</h1>
                            <div className={'subtitle'}>
                                <a style={{color: "#99f"}} target={'_blank'}
                                   href="https://stake.with.starlingcyber.net/#/swap">Swap now</a>
                            </div>
                        </div>
                    </div>

                    <div className={'row pt-4'}>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim1} icon={'technology/icon1.svg'} title={'Private Swaps'} text={'Users can swap between any pair of assets without revealing their trading history to the world using ZSwap, Penumbra’s shielded automated market maker design based on frequent batch auctions.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim3} icon={'technology/icon11.svg'} title={'No MEV'} text={'Transparent chains allow miners to extract value (MEV) by inspecting the contents of transactions and reordering or front-running them. But as a fully shielded chain, Penumbra has no MEV, because transactions do not leak data about user activity.'}/>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default SwapPage
